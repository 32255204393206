
const en = {
  messages: {
    SeemsLikeYoureOffline: "Seems like you're offline",
    NoInternet: "We couldn't reach the server. Please check your internet connection and try again.",
    Unauthorized: "Unauthorized",
    PleaseLoginToContinue: "Please login to continue.",
  },
  formItems: {
    buttonSignIn: "Sign in",
    buttonSignUp: "Sign up",
    labelFirstName: "First name",
    labelLastName: "Last name",
    labelEmail: "Email",
    labelWorkEmail: "Work email",
    labelPassword: "Password",
  },
  menuItems: {
    Home: "Home",
    Documents: "Documents",
    Threads: "Threads",
    Deliverables: "Deliverables",
    Lists: "Lists",
    Workflows: "Workflows",
    Models: "Models",
    Members: "Members",
    Companies: "Companies",
    Teams: "Teams",
    Directory: "Directory",
    Settings: "Settings",
    Preferences: "Preferences",
    AdminConsole: "Admin console",
    LogOut: "Log out",
  },
  entities: {
    Account: {
      Account: "Account",
      Administrators: "Administrators",
      Settings: "Settings",
      General: "General",
      AccountName: "Account name",
      Advanced: "Advanced",
      AccountID: "Account ID",
      InvitePeople: "Invite people",
      InviteAdminsTo: "Invite administrators to",
      AddAdminNotice: "Adding account administrators will allow them to create and configure new projects",
    },
    Organization: "Organization",
    Project: {
      Project: "Project",
      Projects: "Projects",
      Name: "Name",
      Number: "Number",
      StartDate: "Start date",
      EndDate: "End date",
      CreatedOn: "Created on",
      CreatedBy: "Created by",
      CreateProject: "Create project",
    },
    Profile: "Profile",
    User: {
      Name: "Name",
      FullName: "Full name",
      FirstName: "First name",
      LastName: "Last name",
      Email: "Email",
      JobTitle: "Job title",
      Department: "Department or team",
      About: "About me",
      Language: "Language",
      Company: "Company",
      Team: "Team",
    },
    Space: {
      Space: "Space",
      Permission: {
        Permission: "Access level",
        Admin: "Administrator",
        Collaborator: "Collaborator",
        Guest: "Guest",
      }
    },
    List: {
      List: "List",
      Lists: "Lists",
    },
    Review: {
      Review: "Review",
      Reviews: "Reviews",
      Template: "Template",
      Templates: "Templates",
      AddTemplate: "Add template",
      StartReview: "Start review",
    },
    Thread: {
      Thread: "Thread",
      Threads: "Threads",
    }
  },
  pages: {
    UserSettings: {
      Settings: "Settings",
      General: "General",
      Professional: "Professional",
      Regional: "Regional",
      About: {
        About: "About",
        Version: "Version",
        Website: "Website",
        PrivacyPolicy: "Privacy policy",
        TermsOfService: "Terms of service",
        Support: "Support",
        Copyright: "Copyright"
      }
    },
    SpaceHome: {
      Welcome: "Welcome",
    },
    SpaceMembers: {
      AddMembers: "Add members"
    },
    SpaceTeams: {
      AddTeam: "Add team",
      TeamName: "Team name",
      EnterTeamName: "Enter a team name",
    },
    MemberInvite: {
      PageHeading: "Invite project members",
      InputPlaceholder: "Invite users by email",
      Enter: "Enter",
      RemoveMember: "Remove member from the list",
      Clear: "Clear",
      Cancel: "Cancel",
      ClearConfirm: "Are you sure you want to clear emails?",
      Send: "Send invitations",
      MissingFields: "Please fill in all the fields",
    },
    CompanyInvite: {
      AddCompany: "Add company",
      Name: "Name",
      Code: "Code",
      Status: "Status",
      Trade: "Trade",
      CompanyName: "Company name",
      EnterCompanyName: "Enter a company name",
      CompanyCode: "Company code",
      EnterCompanyCode: "Enter a company code",
      TradeCompanyType: "Trade or company type",
      SelectTradeCompanyType: "Select trade or company type",
      CompanyWebsite: "Company website",
      EnterCompanyWebsite: "Enter a company website",
    },
  },
  general: {
    Filter: "Filter",
    Sort: "Sort",
    Internal: "Internal",
    External: "External",
    NoData: "No data",
    AddedOn: "Added on",
    Type: "Type",
    Status: "Status",
    Empty: "Empty",
    Invite: "Invite",
    Create: "Create",
    Cancel: "Cancel",
  },
  notifications: {
    CopiedToClipboard: "Copied to clipboard",
  }
};

export type AppLocaleInterface = typeof en;

export default en;
