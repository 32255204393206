import {
  FolderOutlined,
  LayoutOutlined,
  MailOutlined,
  MenuOutlined,
  NodeExpandOutlined,
  SettingOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Link, useRouterState } from "@tanstack/react-router";
import { Menu } from "antd";
import { useSpaceContext } from "lib/context/SpaceContext";
import { useTr } from "lib/i18n";
import { FC } from "react";
import { ProjectMenuContainer } from "./styled";

const ProjectNavbar: FC = () => {
  const { T } = useTr();
  const router = useRouterState();
  const { data: { uid } } = useSpaceContext();

  const items = [
    {
      label: <Link to="/s/$id/home" params={{ id: uid }}>
        {T("menuItems.Home")}
      </Link>,
      key: "home",
      icon: <LayoutOutlined />,
    },
    {
      label: <Link to="/s/$id/documents" params={{ id: uid }}>
        {T("menuItems.Documents")}
      </Link>,
      key: "documents",
      icon: <FolderOutlined />,
    },
    {
      label: <Link to="/s/$id/threads" params={{ id: uid }}>
        {T("menuItems.Threads")}
      </Link>,
      key: "threads",
      icon: <MailOutlined />,
    },
    {
      label: <Link to="/s/$id/reviews" params={{ id: uid }}>{T("entities.Review.Reviews")}</Link>,
      key: "reviews",
      icon: <NodeExpandOutlined />,
    },
    {
      label: <Link to="/s/$id/lists" params={{ id: uid }}>{T("menuItems.Lists")}</Link>,
      key: "lists",
      icon: <MenuOutlined />,
    },
    {
      label: <Link to="/s/$id/directory" params={{ id: uid }}>
        {T("menuItems.Directory")}
      </Link>,
      key: "directory",
      icon: <TeamOutlined />,
    },
    {
      label: <Link to="/s/$id/settings" params={{ id: uid }}>
        {T("menuItems.Settings")}
      </Link>,
      key: "settings",
      icon: <SettingOutlined />
    },
  ];

  const currentKeys = items
    .filter(item => router.location.pathname.includes(String(item.key)))
    .map(item => item.key);

  return (
    <ProjectMenuContainer>
      <Menu
        selectedKeys={currentKeys}
        mode="horizontal"
        items={items}
        style={{ padding: "0 34px" }}
      />
    </ProjectMenuContainer>
  );
};

export default ProjectNavbar;
