import { AppLocaleInterface } from "./en";

const fr: AppLocaleInterface = {
  messages: {
    SeemsLikeYoureOffline: "Il semble que vous soyez hors-ligne",
    NoInternet: "Nous n'avons pas pu atteindre le serveur. Veuillez vérifier votre connexion internet et réessayer.",
    Unauthorized: "Non autorisé",
    PleaseLoginToContinue: "Veuillez vous connecter pour continuer.",
  },
  formItems: {
    buttonSignIn: "Se connecter",
    buttonSignUp: "S'inscrire",
    labelFirstName: "Prénom",
    labelLastName: "Nom de famille",
    labelEmail: "Email",
    labelWorkEmail: "Email professionnel",
    labelPassword: "Mot de passe"
  },
  menuItems: {
    Home: "Accueil", // GPT
    Documents: "Documents", // GPT
    Threads: "Threads", // GPT
    Deliverables: "Livrables", // GPT
    Lists: "Listes", // GPT
    Workflows: "Processus", // Asana
    Models: "Modèles", // GPT
    Members: "Membres", // GPT
    Companies: "Entreprises", // GPT
    Teams: "Équipes",
    Directory: "Annuaire", // GPT
    Settings: "Paramètres", // GPT
    Preferences: "Préférences", // GPT
    AdminConsole: "Console d’administration", // GPT
    LogOut: "Se déconnecter", // GPT
  },
  entities: {
    Organization: "Organization",
    Account: {
      Account: "Compte", // GPT
      Administrators: "Administrateurs", // GPT
      Settings: "Paramètres", // GPT
      General: "Général", // GPT
      AccountName: "Nom du compte", // GPT
      Advanced: "Avancé", // GPT
      AccountID: "ID du compte", // GPT
      InvitePeople: "Inviter des personnes", // GPT
      InviteAdminsTo: "Inviter un administrateur à", // GPT
      AddAdminNotice: "L’ajout d’administrateurs de compte leur permettra de créer et de configurer de nouveaux projets",
    },
    Project: {
      Project: "Projet", // GPT
      Projects: "Projets", // GPT
      Name: "Nom", // GPT
      Number: "Numéro", // GPT
      StartDate: "Date de début", // GPT
      EndDate: "Date de fin", // GPT
      CreatedOn: "Créé le", // GPT
      CreatedBy: "Créé par", // GPT
      CreateProject: "Créer un projet", // GPT
    },
    Profile: "Profile",
    User: {
      Name: "Nom", // GPT
      FullName: "Nom complet", // GPT
      FirstName: "Prénom", // GPT
      LastName: "Nom de famille", // GPT
      Email: "Courriel", // GPT
      JobTitle: "Poste", // Asana
      Department: "Service ou équipe", // Asana
      About: "À mon sujet", // Asana
      Language: "Langue",  // Asana
      Company: "Entreprise",  // GPT
      Team: "Équipe",  // GPT
    },
    Space: {
      Space: "Space",
      Permission: {
        Permission: "Niveau d’accès", // GPT
        Admin: "Administrateur", // GPT
        Collaborator: "Collaborateur", // GPT
        Guest: "Invité", // GPT
      }
    },
    List: {
      List: "Liste", //
      Lists: "Listes", //
    },
    Review: {
      Review: "Revue", // GPT
      Reviews: "Revues", // GPT
      Template: "Modèle", // GPT
      Templates: "Modèles", // GPT
      AddTemplate: "Ajouter un modèle", // GPT
      StartReview: "Commencer la revue", // GPT
    },
    Thread: {
      Thread: "Thread",
      Threads: "Threads",
    }
  },
  pages: {
    UserSettings: {
      Settings: "Paramètres", // Asana
      General: "Général", // GPT
      Professional: "Professionnel", // GPT
      Regional: "Régional", // GPT
      About: {
        About: "À propos", // GPT
        Version: "Version", // GPT
        Website: "Site web", // GPT
        PrivacyPolicy: "Politique de confidentialité", // GPT
        TermsOfService: "Conditions d'utilisation", // GPT
        Support: "Support", // GPT
        Copyright: "Droits d'auteur" // GPT
      }
    },
    SpaceHome: {
      Welcome: "Bienvenue",
    },
    SpaceMembers: {
      AddMembers: "Ajouter des membres", // GPT
    },
    SpaceTeams: {
      AddTeam: "Ajouter une équipe", // GPT
      TeamName: "Nom de l’équipe", // GPT
      EnterTeamName: "Saisir le nom de l’équipe", // GPT
    },
    MemberInvite: {
      PageHeading: "Inviter des membres au projet", // GPT
      InputPlaceholder: "Inviter des utilisateurs par courriel", // GPT
      Enter: "Entrer", // GPT
      RemoveMember: "Retirer le membre de la liste", // GPT
      Clear: "Effacer", // GPT
      Cancel: "Annuler", // GPT
      ClearConfirm: "Êtes-vous sûr de vouloir effacer les courriels ?", // GPT
      Send: "Envoyer les invitations", // GPT
      MissingFields: "Veuillez remplir tous les champs", // GPT
    },
    CompanyInvite: {
      AddCompany: "Ajouter une entreprise",
      Name: "Nom",
      Code: "Code",
      Status: "Statut",
      Trade: "Métier",
      CompanyName: "Nom de l’entreprise",
      EnterCompanyName: "Saisir le nom de l’entreprise",
      CompanyCode: "Code de l’entreprise",
      EnterCompanyCode: "Saisir un code pour l’entreprise",
      TradeCompanyType: "Métier ou type d’entreprise",
      SelectTradeCompanyType: "Sélectionnez le métier ou type d’entreprise",
      CompanyWebsite: "Site web de l’entreprise",
      EnterCompanyWebsite: "Saisir le site web de l’entreprise",
    },
  },
  general: {
    Filter: "Filtrer", // GPT
    Sort: "Trier", // GPT
    Internal: "Interne", // GPT
    External: "Externe", // GPT
    NoData: "Aucune donnée", // GPT
    AddedOn: "Ajouté le", // GPT
    Type: "Type", // GPT
    Status: "Statut", // GPT
    Empty: "Vide", // GPT
    Invite: "Inviter", // GPT
    Create: "Créer", // GPT
    Cancel: "Annuler", // GPT
  },
  notifications: {
    CopiedToClipboard: "Copié dans le presse-papiers",
  }
};

export default fr;
