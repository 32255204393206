import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const PageContainer = styled.div`
  padding: 16px 50px;
  .ant-form-item {
    margin-bottom: 0;
  }
`;

export const BarContainerSecondary = styled.div`
  padding: 16px 50px;
`;

export const AntTableContainer = styled.div`
  //padding: 0 50px;
  th.ant-table-cell {
    padding: 0 !important;
    font-size: 14px;
    font-weight: 400 !important;
  }
`;

export const TableMargin = styled.div`
  padding: 0 50px;
`;

export const AntFormContainer = styled.div<{ labelColor?: string }>`
  label {
    font-size: 13px !important;
    font-weight: 500;
    ${({ labelColor }) => labelColor && css`
      color: ${labelColor} !important;
    `}
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px !important;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
  .ant-input-number-handler-wrap {
    display: none;
  }
`;
